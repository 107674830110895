import React, { Component } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import tw from "twin.macro"
import { FormattedMessage } from "gatsby-plugin-intl"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import firstStep from "../images/icons/firststep-icon.svg"
import secondStep from "../images/icons/secondstep-icon.svg"
import thirdStep from "../images/icons/thirdstep-icon.svg"
import firstStepMobile from "../images/icons/firststep-icon-small.svg"
import secondStepMobile from "../images/icons/secondstep-icon-small.svg"
import thirdStepMobile from "../images/icons/thirdstep-icon-small.svg"
import arrowRight from "../images/icons/arrow-right-icon.svg"
import arrowLeft from "../images/icons/arrow-left-icon.svg"

const Wrapper = styled.div`
  margin: 150px auto 0;
  @media only screen and (max-width: 1279px) {
    margin: 120px auto 0;
  }
  @media only screen and (max-width: 767px) {
    margin: 84px auto 0;
  }
`

const Container = styled.div`
  ${tw`px-5 relative max-w-default w-full `};
  margin: 0px auto;
`

const Title = styled.h1`
  ${tw`text-32 sm:text-40 md:text-46 font-semibold pl-0 sm:pl-6 md:pl-14 text-dark leading-snug`}
`

const Content = styled.div`
  ${tw`relative flex flex-col`}
`
const StepsWrapper = styled.div`
  ${tw`hidden sm:flex flex-col lg:flex-row justify-between lg:justify-around px-5 lg:px-0 pt-20 flex-wrap`}
  margin: 0 -32px 0 -32px;
  width: calc(100% + 64px);
  @media only screen and (max-width: 1279px) {
    margin: 0 -20px 0 -20px;
    width: calc(100% + 40px);
  }
`
const StepWrapper = styled.div`
  ${tw`flex relative outline-none focus:outline-none shadow-valueAbout bg-white flex-col mb-24 sm:mb-12 md:mb-16 py-9 sm:py-10 px-30px sm:px-12 lg:mx-5 xl:mx-8`}
  flex:1;
  border-radius: 43px;
`

const StepHeader = styled.div`
  ${tw`pb-4`}
`
const StepTitle = styled.h2`
  ${tw`text-dark pt-4 sm:pt-5 text-22 font-semibold `}
`
const StepDescription = styled.p`
  ${tw`text-dark text-base sm:text-lg leading-32`}
`

const StepsWrapperMobile = styled(Slider)`
  ${tw`block sm:hidden w-full outline-none focus:outline-none`}
`

const StepWrapperMobile = styled.div`
  ${tw`px-5 pt-10 outline-none focus:outline-none`}
`

const ArrowsWrapper = styled.div`
  ${tw` absolute self-center`}
  display: none !important;
  bottom: -100px;
  margin: 0 auto;
  z-index: 99;
  @media only screen and (max-width: 1023px) {
    display: flex !important;
    width: calc(100% - 96px);
  }
  @media only screen and (max-width: 767px) {
    display: flex !important;
    justify-content: center;
    width: calc(100% - 66px);
  }
`

const ArrowRight = styled.div`
  ${tw`bg-primary flex justify-center items-center rounded-full `}
  padding: 17px 28px 17px 32px;
  margin: 5px;
`

const ArrowLeft = styled.div`
  ${tw`bg-primary flex justify-center items-center rounded-full`}
  padding: 17px 32px 17px 28px;
  margin: 5px;
`

export default class BetterFuture extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      current: 0,
    }

    this.nextMobile = this.nextMobile.bind(this)
    this.prevMobile = this.prevMobile.bind(this)
  }

  nextMobile() {
    this.state.nav1.slickNext()
  }

  prevMobile() {
    this.state.nav1.slickPrev()
  }

  changeCurrent(current) {
    this.setState({
      current: current,
    })
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
    })
  }

  render() {
    const settings = {
      beforeChange: (current, next) => {
        this.changeCurrent(next)
      },
    }
    return (
      <Wrapper>
        <Container>
          <Content>
            <Title>
              <FormattedMessage id="about.better_future.title" />
            </Title>
          </Content>
          <StepsWrapper>
            <StepWrapper>
              <StepHeader>
                <img src={firstStep} />
                <StepTitle>
                  1. <FormattedMessage id="about.better_future.step_title_1" />
                </StepTitle>
              </StepHeader>
              <StepDescription>
                <FormattedMessage id="about.better_future.step_description_1_part_1" />
                <b>
                  <FormattedMessage id="about.better_future.step_description_1_bold" />
                </b>
                <FormattedMessage id="about.better_future.step_description_1_part_2" />
              </StepDescription>
            </StepWrapper>
            <StepWrapper>
              <StepHeader>
                <img src={secondStep} />
                <StepTitle>
                  2. <FormattedMessage id="about.better_future.step_title_2" />
                </StepTitle>
              </StepHeader>
              <StepDescription>
                <FormattedMessage id="about.better_future.step_description_2_part_1" />
                <b>
                  <FormattedMessage id="about.better_future.step_description_2_bold" />
                </b>
                <FormattedMessage id="about.better_future.step_description_2_part_2" />
              </StepDescription>
            </StepWrapper>
            <StepWrapper>
              <StepHeader>
                <img src={thirdStep} />
                <StepTitle>
                  3. <FormattedMessage id="about.better_future.step_title_3" />
                </StepTitle>
              </StepHeader>
              <StepDescription>
                <FormattedMessage id="about.better_future.step_description_3_part_1" />
                <b>
                  <FormattedMessage id="about.better_future.step_description_3_bold" />
                </b>
                <FormattedMessage id="about.better_future.step_description_3_part_2" />
              </StepDescription>
            </StepWrapper>
          </StepsWrapper>
        </Container>
        <StepsWrapperMobile
          className="StepsWrapper"
          asNavFor={this.state.nav2}
          arrows={false}
          fade={true}
          draggable={false}
          ref={slider => (this.slider1 = slider)}
          {...settings}
        >
          <StepWrapperMobile>
            <StepWrapper>
              <StepHeader>
                <img src={firstStepMobile} />
                <StepTitle>
                  1. <FormattedMessage id="about.better_future.step_title_1" />
                </StepTitle>
              </StepHeader>
              <StepDescription>
                <FormattedMessage id="about.better_future.step_description_1_part_1" />
                <b>
                  <FormattedMessage id="about.better_future.step_description_1_bold" />
                </b>
                <FormattedMessage id="about.better_future.step_description_1_part_2" />
              </StepDescription>
              <ArrowsWrapper>
                <ArrowLeft
                  style={{
                    opacity: this.state.current === 0 ? "0.3" : "1",
                  }}
                >
                  <img src={arrowLeft} />
                </ArrowLeft>
                <ArrowRight onClick={this.nextMobile}>
                  <img src={arrowRight} />
                </ArrowRight>
              </ArrowsWrapper>
            </StepWrapper>
          </StepWrapperMobile>

          <StepWrapperMobile>
            <StepWrapper>
              <StepHeader>
                <img src={secondStepMobile} />
                <StepTitle>
                  2. <FormattedMessage id="about.better_future.step_title_1" />
                </StepTitle>
              </StepHeader>
              <StepDescription>
                <FormattedMessage id="about.better_future.step_description_2_part_1" />
                <b>
                  <FormattedMessage id="about.better_future.step_description_2_bold" />
                </b>
                <FormattedMessage id="about.better_future.step_description_2_part_2" />
              </StepDescription>
              <ArrowsWrapper>
                <ArrowLeft
                  onClick={this.prevMobile}
                  style={{
                    opacity: this.state.current === 0 ? "0.3" : "1",
                  }}
                >
                  <img src={arrowLeft} />
                </ArrowLeft>
                <ArrowRight onClick={this.nextMobile}>
                  <img src={arrowRight} />
                </ArrowRight>
              </ArrowsWrapper>
            </StepWrapper>
          </StepWrapperMobile>

          <StepWrapperMobile>
            <StepWrapper>
              <StepHeader>
                <img src={thirdStepMobile} />
                <StepTitle>
                  3. <FormattedMessage id="about.better_future.step_title_1" />
                </StepTitle>
              </StepHeader>
              <StepDescription>
                <FormattedMessage id="about.better_future.step_description_3_part_1" />
                <b>
                  <FormattedMessage id="about.better_future.step_description_3_bold" />
                </b>
                <FormattedMessage id="about.better_future.step_description_3_part_2" />
              </StepDescription>
              <ArrowsWrapper>
                <ArrowLeft
                  onClick={this.prevMobile}
                  style={{
                    opacity: this.state.current === 0 ? "0.3" : "1",
                  }}
                >
                  <img src={arrowLeft} />
                </ArrowLeft>
                <ArrowRight
                  style={{
                    opacity: this.state.current === 2 ? "0.3" : "1",
                  }}
                >
                  <img src={arrowRight} />
                </ArrowRight>
              </ArrowsWrapper>
            </StepWrapper>
          </StepWrapperMobile>
        </StepsWrapperMobile>
      </Wrapper>
    )
  }
}
