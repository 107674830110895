import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { FormattedMessage } from "gatsby-plugin-intl"

import trustIcon from "../images/icons/trust-icon.svg"
import trustIconSmall from "../images/icons/trust-icon-small.svg"
import securityIcon from "../images/icons/security-icon.svg"
import securityIconSmall from "../images/icons/security-icon-small.svg"
import qualityIcon from "../images/icons/quality-icon.svg"
import qualityIconSmall from "../images/icons/quality-icon-small.svg"
import kindnessIcon from "../images/icons/kindness-icon.svg"
import kindnessIconSmall from "../images/icons/kindness-icon-small.svg"

const Container = styled.div`
  ${tw`px-5 relative max-w-default w-full pb-40`};
  margin: 150px auto 0;
`

const Title = styled.h1`
  ${tw`text-40 md:text-46 font-semibold text-dark`}
`

const Content = styled.div`
  ${tw`relative flex flex-col`}
`

const Description = styled.p`
  ${tw`text-base sm:text-lg text-dark font-light leading-32 pt-8`}
  max-width: 1000px;
`
const ValuesWrapper = styled.div`
  ${tw`flex justify-between lg:justify-around pt-20 flex-wrap`}
`
const ValueWrapper = styled.div`
  ${tw`flex flex-col items-center w-1/2 sm:w-auto mb-12 sm:mb-0`}
`
const Value = styled.div`
  ${tw`shadow-valueAbout bg-white flex justify-center items-center rounded-full`}
  height: 136px;
  width: 136px;
  @media only screen and (max-width: 638px) {
    height: 95px;
    width: 95px;
  }
`
const ValueText = styled.h2`
  ${tw`text-dark text-base sm:text-xl md:text-22 text-center font-semibold pt-5 sm:pt-6 md:pt-7`}
`
const IconDesktop = styled.img`
  ${tw`hidden sm:block`}
`
const IconMobile = styled.img`
  ${tw`block sm:hidden`}
`

const LifeWithUs = () => {
  return (
    <Container>
      <Content>
        <Title>
          <FormattedMessage id="about.life_with_us" />
        </Title>
        <Description>
          <FormattedMessage id="about.life_with_us_description_1" />
          <b>
            <FormattedMessage id="about.life_with_us_description_bold" />
          </b>
          <FormattedMessage id="about.life_with_us_description_2" />
        </Description>
      </Content>
      <ValuesWrapper>
        <ValueWrapper>
          <Value>
            <IconDesktop src={trustIcon} />
            <IconMobile src={trustIconSmall} />
          </Value>
          <ValueText>
            <FormattedMessage id="about.values.trust" />
          </ValueText>
        </ValueWrapper>
        <ValueWrapper>
          <Value>
            <IconDesktop src={securityIcon} />
            <IconMobile src={securityIconSmall} />
          </Value>
          <ValueText>
            <FormattedMessage id="about.values.security" />
          </ValueText>
        </ValueWrapper>
        <ValueWrapper>
          <Value>
            <IconDesktop src={qualityIcon} />
            <IconMobile src={qualityIconSmall} />
          </Value>
          <ValueText>
            <FormattedMessage id="about.values.quality" />
          </ValueText>
        </ValueWrapper>
        <ValueWrapper>
          <Value>
            <IconDesktop src={kindnessIcon} />
            <IconMobile src={kindnessIconSmall} />
          </Value>
          <ValueText>
            <FormattedMessage id="about.values.friendship" />
          </ValueText>
        </ValueWrapper>
      </ValuesWrapper>
    </Container>
  )
}

export default LifeWithUs
