import React from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import styled from "styled-components"
import tw from "twin.macro"

import Layout from "../components/layout"
import Head from "../components/head"
import LifeWithUs from "../components/aboutLifeWithUs"
import BetterFuture from "../components/aboutBetterFuture"

const IntroductionContent = styled.div`
  ${tw` px-5 relative lg:items-center pt-4 sm:pt-6 md:pt-12 lg:pt-20 pb-7 sm:pb-10 md:pb-16 lg:pb-24  max-w-default  flex flex-col lg:flex-row w-full`};
  margin: 10px auto 14px;
`
const Title = styled.h1`
  ${tw`text-40 sm:text-5xl md:text-xxl leading-snug w-full md:w-2/5 font-semibold text-dark`}
  max-width: 430px;
`

const DescriptionWrapper = styled.div`
  ${tw`w-full lg:w-3/5 pt-8 lg:pt-0`}
`

const Description = styled.p`
  ${tw`text-base md:text-lg text-dark font-light lg:pl-12 leading-testimonial`}
`
const Divider = styled.div`
  ${tw`bg-dark h-32 py-3 w-full`}
`

const AboutPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Head
        lang={intl.locale}
        title={intl.formatMessage({ id: "navigation.about" })}
      />
      <IntroductionContent>
        <Title>
          <FormattedMessage id="about.title" />
        </Title>
        <DescriptionWrapper>
          <Description>
            <FormattedMessage id="about.description_part_1" />
            <b>
              <FormattedMessage id="about.description_part_bold_1" />
            </b>
            <FormattedMessage id="about.description_part_2" />
            <b>
              <FormattedMessage id="about.description_part_bold_2" />
            </b>
            <FormattedMessage id="about.description_part_3" />
          </Description>
        </DescriptionWrapper>
      </IntroductionContent>
      <Divider></Divider>
      <BetterFuture></BetterFuture>
      <LifeWithUs></LifeWithUs>
    </Layout>
  )
}

export default AboutPage
